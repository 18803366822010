import React from 'react';
import { Route } from 'react-router-dom';
const SigninComponent = React.lazy(() => import("../components/edvenswa.emportal.auth/pages/Signin"));

function MissingRoute() {
    // return <>   <Navigate to={{ pathname: '/' }} />   </>
    return <Route path="*" element={<SigninComponent/>} />
}

export { MissingRoute };

